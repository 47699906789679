@charset 'UTF-8';

// wrapper
// -------
.global_navigation {
  display: none;
  position: relative;
  width: 100%;

  @include media(lg) {
    display: block;
    height: 100%;

    nav {
      height: 100%;
    }
  }
}

// メディアクエリによる可視と不可視
// ------------------------
.sm_nav {
  display: block;
  @include media(lg) {
    display: none;
  }
}


// navigation
// ----------
ul.global_navigation_menu {
  background: $white-color;
  display: none;
  position: fixed;
  top: map-get( $height-header, sm ) + 1px;
  left: 0;
  width: 100%;
  z-index: 2;

  li {
    text-align: center;

    a {
      color: $body-text-color;
      display: block;
      font-size: 1.7rem;
      height: 100%;
      padding: 1.125em 0;
      transition: background $transition;
      &:hover {
        background: $five-color;
      }
    }
  }

  // PC用
  @include media(lg) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0;
    height: 100%;

    li {
      flex: 1;
      height: 100%;
      line-height: map-get( $height-header, sm ) + px;
      text-align: center;

      a {
        font-size: 1.6rem;
        padding: 0;
      }
    }
  }
}
