@charset 'UTF-8';


// COMMON page
// -----------

.main_contents_column_wrapper {
  display: flex;
  flex-flow: column wrap;
  @include media(md){
    flex-flow: row wrap;
  }

  .left_column {
    order: 0;
    width: 100%;
    @include media(md){
      flex: 1 0 0;
      padding-right: 3.2rem;
    }
  }

  .right_column {
    order: 1;
    width: 100%;
    @include media(md){
      flex: 0 0 17.8rem;
    }
    @include media(lg){
      flex: 0 0 24.4rem;
    }
  }
}


// Right column

.side_flex {
  margin-top: 1em;
  @include media(md) {
    margin-top: 0;
  }

  .items {
    flex: 0 0 100%;
    width: 100%;
    min-width: 100%;
    padding-bottom: 1em;
  }
}

.text_link {
  background: $gray-200;
  color: $base-color;
  display: block;
  padding: 1em;
  position: relative;
  transition: color .3s;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.5em;
    margin: auto;
    vertical-align: middle;
  }
  &:before {
    background: $base-color;
    width: 1.2em;
    height: 1.2em;
    border-radius: 50%;
  }
  &:after {
    left: 0.8em;
    width: 0.5em;
    height: 0.5em;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }

  & span {
    display: block;
    line-height: 1.4;
    margin-left: 1.2em;
  }
  &:hover {
    color: lighten($base-color, 15%);
    text-decoration: none;
  }
}


// TOP page
// --------

// Slider
.slider_wrapper {
  width: 100%;
  img {
    width: 100%;
  }
}

// NEWS

/* 日付横のラベル */
$span_label_color: (
                    'default': ( 'bg' : $base-color,   'color' : #fff ),
                    'news'   : ( 'bg' : $third-color,   'color' : #fff ),
                    'info'   : ( 'bg' : $first-color,  'color' : #fff ),
                    'topix'  : ( 'bg' : $second-color, 'color' : #fff )
                   ) !default;

ul.top_news_list {
  margin-bottom: 2em;
  li {
    border-bottom: 1px dotted $gray-400;
    position: relative;

    &.no_link {
      padding: 1em;
    }

    a {
      color: inherit;
      display: block;
      padding: 1em 3em 1em 1em;
      transition: background $transition;
      &:hover {
        background: $five-color;
        &::after {
          transform: translateX(.6em) rotate(45deg);
        }
      }
      &::after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border-right: 3px solid $base-color;
        border-top: 3px solid $base-color;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: transform $transition;
      }
    }

    dl {
      dt {
        font-weight: bold;
        margin-bottom: .5em;

        span {
          display: inline-block;
          font-size: .8em;
          line-height: 1;
          margin-left: .8em;
          padding: .6em .45em;
          text-align: center;
          vertical-align: baseline;
          // bacground color pattern
          @each $name, $value in $span_label_color {
            &.#{$name} {
              background: map-get( $value, 'bg' );
              color: map-get( $value, 'color' );
            }
          }
        }
      }
      dd {
        &.news-title {
          color: $gray-600;
          font-weight: bold;
          margin-bottom: .325em;
        }
      }
    }
  }
}
//
.info_archive_link_button {
  background: $white-color;
  border: 1px solid $base-color;
  color: $base-color;
  display: block;
  line-height: 1;
  padding: 1em 0;
  text-align: center;
  transition: background $transition;
  &:hover {
    background: $five-color;
    color: $base-color;
  }
}
