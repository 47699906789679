@charset 'UTF-8';

// typography
// ----------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
  background: $five-color;
  font-size: 1.25em;
  font-weight: normal;
  margin: 0;
  padding: .75em 1em .75em 1.5em;
  position: relative;
  text-shadow: 1px 1px 1px $white-color;
  &:after {
    background: $base-color;
    border-radius: 4px;
    content: '';
    height: calc( 100% - 1em );
    position: absolute;
    top: .5em;
    left: .5em;
    width: 6px;
  }
}

h2 {
  font-size: $h2-font-size;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  color: $gray-800;
  font-size: $h3-font-size * .725;
  font-weight: normal;
  position: relative;
  margin: 1em 0;
  padding: .5em 0 .5em 1em;
  &:not(:nth-of-type(1)) {
    margin: 2em 0 1em;
  }
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}


p {
  font-size: inherit;
  letter-spacing: map-unit( $paragraph-letter-spacing, sm );
  line-height: map-unit( $paragraph-line-height, sm );
  margin-bottom: map-unit( $paragraph-margin, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-unit( $paragraph-letter-spacing, md );
    line-height: map-unit( $paragraph-line-height, md );
    margin-bottom: map-unit( $paragraph-margin, md );
  }
  @include media(lg) {
    letter-spacing: map-unit( $paragraph-letter-spacing, lg );
    line-height: map-unit( $paragraph-line-height, lg );
    margin-bottom: map-unit( $paragraph-margin, lg );
  }
}

// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
.phrase {
  margin-bottom: 0 !important;
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

figcaption {
  color: $gray-600;
  font-size: .92307em;
  margin-top: .2em;
}

// code
// ----
pre {
  background: #f2f2f2;
  margin: 1em 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: #545454;
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
  font-weight: bold;
  vertical-align: initial;
}
