@charset 'UTF-8';

// footer
// ------

footer {
  background: transparent;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(md) {
    padding-top: map-unit( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  border-top: 1px solid $gray-200;
  position: relative;
  padding: 0 map-unit( $footer-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $footer-container-padding, md );
  }
  @include media(lg) {
    padding: 0 map-unit( $footer-container-padding, lg );
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}

.footer-inner {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;

  @include media(md){
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
  }

  .footer-inner-left {
    padding-bottom: 1em;
    width: 100%;

    @include media(md) {
      padding-bottom: 0;
      width: auto;
    }

    .footer-title {
      color: $gray-800;
      font-size: 1em;
      font-weight: bold;
      @include media(md) {
        font-size: 1em;
      }
      @include media(lg) {
        font-size: 1em;
      }
    }
  }

  .footer-inner-right {
    font-size: 1em;
    @include media(md) {
      font-size: 1em;
    }
    @include media(lg) {
      font-size: 1em;
    }
  }
}

// Link
ul.footer_utility_link {
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  li {
    &:not(:last-child) {
      border-bottom: 1px solid $five-color;
    }

    a {
      background: $white-color;
      color: $base-color;
      display: block;
      padding: 1em;
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: .4em 0 .4em .4em;
        border-color: transparent transparent transparent $base-color;
        margin-right: .2em;
      }
      &:hover {
        text-decoration: underline;
      }
      @include media(md){
        background: transparent;
        padding: 0 1em 0 0;
      }
    }
  }
  @include media(md){
    flex-flow: row wrap;
  }
}
