@charset 'UTF-8';

// Pagetop
// -------
.pagetop {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;

  @include media(lg) {
    bottom: 6rem;
    right: 3rem;
  }

  a {
    display: block;
    background-color: rgba( $base-color, .9 );
    text-align: center;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color $transition ease-out, color $transition ease-out;
    width: 44px;
    height: 44px;
    padding: 0.4rem;
    @include media(md) {
      width: 50px;
      height: 50px;
    }
    @include media(lg) {
      width: 60px;
      height: 60px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      border-right: 3px solid #fff;
      border-top: 3px solid #fff;
      transform: rotate(-45deg);
      margin-top: .875em;
      transition: transform $transition ease-out;
      @include media(md) {
        width: 20px;
        height: 20px;
        margin-top: 1em;
      }
      @include media(lg) {
        width: 24px;
        height: 24px;
        margin-top: 1.325em;
      }
    }
  }
}
